<template>
  <div>
    <p>Select the source of funds. For each source of funds, please try and upload as much of the relevant documentation as possible to speed up the application process. If you do not have all of the required documentation on hand, you can upload what you have and complete the application and email the outstanding documentation to us at a later stage.</p>
    <v-row dense class="pa-2">
      <v-col cols="12">
        <source-of-funds-accordian v-model="sourceOfFunds"/>
      </v-col>
    </v-row>

    <v-row dense v-if="error">
      <v-col cols="12">
        <p class="v-messages error--text">{{ error }}</p>
      </v-col>
    </v-row>

    <br/>

    <v-btn text @click="$emit('back')" rounded><v-icon left>mdi-arrow-left</v-icon>&nbsp;Back</v-btn>
    &nbsp;
    <v-btn color="primary" @click="nextClick" rounded>Next&nbsp;<v-icon right>mdi-arrow-right</v-icon></v-btn>
    <br/>
    <br/>
  </div>
</template>

<script>
import SourceOfFundsAccordian from './source-of-funds-page/SourceOfFundsAccordian';

export default {
  components: {
    SourceOfFundsAccordian
  },
  data() {
    return {
      error: null
    }
  },
  computed: {
    sourceOfFunds: {
      get() { return this.$store.state.sourceOfFunds; },
      set(value) { return this.$store.commit('changeSourceOfFunds', value); }
    }
  },
  methods: {
    nextClick() {
      if (this.$store.state.sourceOfFunds.length === 0) {
        this.error = "Please select at least one source of funds.";
      } else {
        this.$emit('next');
      }
    }
  }
}
</script>
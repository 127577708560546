import accounting from 'accounting'

/**
 * Formats a number as a currency string
 * @param {Number} value - The currency amount that should be formatted.
 * @param {String} prefix - The currency symbol that should be prefixed. Defaults to R if none is specified
 * @returns {String} - The formatted currency string
 */
function format(value, prefix) {
  return (!prefix ? 'R ' : prefix + ' ') + accounting.format(value, 2, ' ', '.');
}

/**
 * Converts a formatted currency number to a string
 * @param {String} value - The currency string that should be converted to a number.
 * @returns {Number} - The converted number amount
 */
function unformat(value) {
  return accounting.unformat(value);
}

export default {
  format,
  unformat
}
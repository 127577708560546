<template>
  <div class="py-2">
    <div class="mb-2" v-if="files.length > 0">
      <v-chip class="ma-1" v-for="(file, index) in files" :key="index" close @click:close="() => removeFile(index)">{{ file.name }}</v-chip>
    </div>
    <!--input type="file" style="display: none;" ref="fileInput" @change="fileSelected" :multiple="multiple" />-->
    <v-btn v-if="!(!multiple && files.length > 0)" @click="openFileDialog" v-bind="$attrs">
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: '*'
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() { 
    return {
      files: this.value
    }
  },
  methods: {
    openFileDialog() { 
      const fileSelect = document.createElement("input")
      fileSelect.setAttribute("type", "file");
      fileSelect.setAttribute("multiple", this.multiple);
      fileSelect.setAttribute("accept", this.accept)
      fileSelect.addEventListener("change", (e) => {
        this.files.push(...e.target.files);

        this.$emit('input', this.files);
      });

      fileSelect.click();
    },
    removeFile(index) { 
      const file = this.files[index];

      this.files.splice(index, 1);
      
      this.$emit('fileRemoved', file);
      this.$emit('input', this.files);
    }
  }
}
</script>
<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left" width="60%">Description</th>
          <th class="text-left" width="40%">Files</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(description, index) in descriptions" :key="description.id">
          <td><v-text-field dense hide-details outlined v-model="description.description" @input="updateStore" /></td>
          <td>
            <file-button small multiple :value="description.files" @input="(files) => filesInput(files, index)">Upload</file-button>
          </td>
          <td>
            <v-btn @click="() => removeDescription(index)" icon color="red"><v-icon>mdi-close</v-icon></v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <br/>
    <v-btn color="primary" @click="addDescription">Add File</v-btn>
  </div>
</template>

<script>
import FileButton from '../../../FileButton';

function makeid(length) {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

export default {
  components: {
    FileButton
  },
  data() { 
    return {
      descriptions: []
    };
  },
  methods: {
    addDescription() { 
      this.descriptions.push({ description: '', files: [], id: makeid(12) });
      
      this.$forceUpdate();
    },
    removeDescription(index) { 
      this.descriptions.splice(index, 1);

      this.updateStore();

      this.$forceUpdate();
    },
    filesInput(files, index) {
      this.descriptions[index].files = files;

      this.updateStore();

      this.$forceUpdate();
    },
    updateStore() {
      const storeFiles = {};
      for (const description of this.descriptions) {
        storeFiles[description.description] = description.files;
      }
      this.$store.commit('sourceOfFundFiles/changeSourceOfFundFiles', { name: 'other', files: storeFiles })
    }
  },
}
</script>
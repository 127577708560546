<template>
  <source-of-fund-files-table v-model="files"/>
</template>

<script>
import SourceOfFundFilesTable from './SourceOfFundFilesTable';

export default {
  components: {
    SourceOfFundFilesTable
  },
  props: {
    sourceOfFund: {
      type: String,
      required: true
    }
  },
  computed: {
    files: {
      get() { return this.$store.state.sourceOfFundFiles[this.sourceOfFund]; },
      set(value) { console.log(value); this.$store.commit('sourceOfFundFiles/changeSourceOfFundFiles', { name: this.sourceOfFund, files: value })}
    }
  }
}
</script>
export default {
  state: {
    firstName: "",
    middleNames: "",
    lastName: "",
    identificationType: "South African ID",
    identityNumber: "",
    identityDocuments: [],
    incomeTaxNumber: "",
    cellphoneNumber: "",
    emailAddress: "",
    investmentAmount: null,
    investmentType: "",
    investmentCountry: "",
    sourceOfFunds: [],
    beneficiaryOfTrust: "",
    existingLoanToTrust: "",
    shareholding20OrMore: "",
    assetsAndLiabilitiesSpreadsheet: null,
  },
  mutations: {
    changeFirstName(state, payload) {
      state.firstName = payload;
    },
    changeMiddleNames(state, payload) {
      state.middleNames = payload;
    },
    changeLastName(state, payload) {
      state.lastName = payload;
    },
    changeIdentificationType(state, payload) {
      state.identificationType = payload;
    },
    changeIdentityNumber(state, payload) {
      state.identityNumber = payload;
    },
    changeIdentityDocuments(state, payload) {
      state.identityDocuments = payload;
    },
    changeIncomeTaxNumber(state, payload) {
      state.incomeTaxNumber = payload;
    },
    changeCellphoneNumber(state, payload) {
      state.cellphoneNumber = payload;
    },
    changeEmailAddress(state, payload) {
      state.emailAddress = payload;
    },
    changeInvestmentAmount(state, payload) {
      state.investmentAmount = payload;
    },
    changeInvestmentType(state, payload) {
      state.investmentType = payload;
    },
    changeInvestmentCountry(state, payload) {
      state.investmentCountry = payload;
    },
    changeSourceOfFunds(state, payload) {
      state.sourceOfFunds = payload;
    },
    changeExistingLoanToTrust(state, payload) {
      state.existingLoanToTrust = payload;
    },
    changeShareholding20OrMore(state, payload) {
      state.shareholding20OrMore = payload;
    },
    changeBeneficiaryOfTrust(state, payload) {
      state.beneficiaryOfTrust = payload;
    },
  },
};

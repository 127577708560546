<template>
  <v-app id="app">
    <page-header/>
    <page-content>
      <tax-clearance-certificate-form/>
    </page-content>
    <page-footer/>
  </v-app>
</template>

<script>
import PageHeader from './components/PageHeader';
import PageContent from './components/PageContent';
import PageFooter from './components/PageFooter';

import TaxClearanceCertificateForm from './components/forms/tax-clearance-certificate-form/TaxClearanceCertificateForm';

export default {
  name: 'App',

  components: {
    PageHeader,
    PageContent,
    PageFooter,
    TaxClearanceCertificateForm
  },

  data: () => ({
    //
  }),
};
</script>

<style>
#app {
  background-color: white;
}
</style>

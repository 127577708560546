<template>
  <v-form ref="reviewPageForm">
    <div v-if="errors.length > 0">
      <v-alert type="error">
        <div v-for="(error,i) in errors" :key="i">{{ error }}</div>
      </v-alert>
      <p></p>
    </div>
    <p>Please review the details below before submitting.</p>
    <v-row>
      <v-col cols="12">
        <personal-details-section />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <foreign-investment-details-section />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <source-of-funds-section/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <assets-and-liabilities-section />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-checkbox :disabled="submitting" :rules="rules.reviewed" v-model="reviewed" label="I have reviewed the details and confirm that all details are correct" color="primary"/>
      </v-col>
    </v-row>
    <br/>

    <v-btn :disabled="submitting" text @click="$emit('back')" rounded><v-icon left>mdi-arrow-left</v-icon>&nbsp;Back</v-btn>
    &nbsp;
    <v-btn :loading="submitting" color="primary" @click="nextClick" rounded>Submit</v-btn>
    <span v-if="submitting" class="ml-4 caption grey--text text--darken-1">Please don't close this page before your application has finished being submitted.</span>
    <br/>
    <br/>
    
  </v-form>
</template>

<script>
import PersonalDetailsSection from './review-page/PersonalDetailsSection';
import ForeignInvestmentDetailsSection from './review-page/ForeignInvestmentDetailsSection';
import SourceOfFundsSection from './review-page/SourceOfFundsSection';
import AssetsAndLiabilitiesSection from './review-page/AssetsAndLiabilitiesSection';

import submission from '../../../utils/form-submission';

export default {
  components: {
    PersonalDetailsSection,
    ForeignInvestmentDetailsSection,
    SourceOfFundsSection,
    AssetsAndLiabilitiesSection
  },
  data()  { 
    return {
      reviewed: false,
      submitting: false,
      errors: [],
      rules: {
        reviewed: [
          v => !!v || 'Please confirm that the details are correct'
        ]
      }
    }
  },
  methods: {
    async nextClick() { 
      if (!this.$refs.reviewPageForm.validate()) {
        return;
      }

      this.errors = false;
      this.submitting = true;
      const errors = await submission.submit();
      this.submitting = false;

      if (!errors) {
        this.$emit('next');
      } else {
        this.errors = errors;
        window.scrollTo(0, 0);
      }
    }
  }
}
</script>
<template>
  <div>
    <div :class="{'pa-0': $vuetify.breakpoint.smAndDown, 'pa-3': $vuetify.breakpoint.mdAndUp}">
      <h1 class="display-1 font-weight-medium">Tax Clearance Certificate Application</h1>
      <div class="grey--text text--darken-1 body-2">Please fill in the form below to apply for a Tax Clearance Certificate.</div>
    </div>
    <v-stepper :value="currentStep" class="elevation-0" alt-labels>
      <v-stepper-header class="elevation-0" outlined>
        <v-stepper-step step="1" :complete="currentStep > 1">Personal Details</v-stepper-step>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
        <v-stepper-step step="2" :complete="currentStep > 2">
          <div class="text-center">
            Foreign Investment Details
          </div>
        </v-stepper-step>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
        <v-stepper-step step="3" :complete="currentStep > 3">Source of Funds</v-stepper-step>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
        <v-stepper-step step="4" :complete="currentStep > 4">
          <div class="text-center">
            Assets and Liabilities
          </div>
        </v-stepper-step>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
        <v-stepper-step step="5" :complete="currentStep > 5">Review</v-stepper-step>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
        <v-stepper-step step="6" :complete="currentStep > 5">Complete</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <div :class="{ 'pa-0': $vuetify.breakpoint.smAndDown, 'pa-3': $vuetify.breakpoint.mdAndUp }">
            <personal-details-page @next="nextPage"/>
          </div>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <div :class="{ 'pa-0': $vuetify.breakpoint.smAndDown, 'pa-3': $vuetify.breakpoint.mdAndUp }">
            <foreign-investment-details-page @next="nextPage" @back="previousPage"/>
          </div>
        </v-stepper-content>
        <v-stepper-content step="3" class="pa-0">
          <div :class="{ 'pa-0': $vuetify.breakpoint.smAndDown, 'pa-3': $vuetify.breakpoint.mdAndUp }">
            <source-of-funds-page @back="previousPage" @next="nextPage" />
          </div>
        </v-stepper-content>
        <v-stepper-content step="4" class="pa-0">
          <div :class="{ 'pa-0': $vuetify.breakpoint.smAndDown, 'pa-3': $vuetify.breakpoint.mdAndUp }">
            <assets-and-liabilities-page  @back="previousPage" @next="nextPage" />
          </div>
        </v-stepper-content>
        <v-stepper-content step="5" class="pa-0">
          <div :class="{ 'pa-0': $vuetify.breakpoint.smAndDown, 'pa-3': $vuetify.breakpoint.mdAndUp }">
            <review-page @back="previousPage" @next="nextPage"/>
          </div>
        </v-stepper-content>
        <v-stepper-content step="6" class="pa-0">
          <div :class="{ 'pa-0': $vuetify.breakpoint.smAndDown, 'pa-3': $vuetify.breakpoint.mdAndUp }">
            <complete-page />
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import PersonalDetailsPage from './PersonalDetailsPage';
import ForeignInvestmentDetailsPage from './ForeignInvestmentDetailsPage';
import SourceOfFundsPage from './SourceOfFundsPage';
import AssetsAndLiabilitiesPage from './AssetsAndLiabilitiesPage';
import ReviewPage from './ReviewPage';
import CompletePage from './CompletePage';

export default {
  components: {
    PersonalDetailsPage,
    ForeignInvestmentDetailsPage,
    SourceOfFundsPage,
    AssetsAndLiabilitiesPage,
    ReviewPage,
    CompletePage
  },
  data() {
    return {
      currentStep: 1
    }
  },
  methods: {
    nextPage() {
      this.currentStep++;
      window.scrollTo(0, 0)
    },
    previousPage() { 
      this.currentStep--;
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style>
@media (max-width: 960px) {
  .v-stepper__step {
    flex-basis: 0 !important;
    padding: 24px 5px !important;
  }

  .v-stepper__step:first-of-type {
    padding-left: 10px !important;
  }

  .v-stepper__step:last-of-type {
    padding-right: 10px !important;
  }
}

</style>
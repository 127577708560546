<template>
  <v-autocomplete flex-grow-0 flex-grow-0 v-bind="$attrs" v-model="selectedValue" @input="handleInput" :items="countries" item-text="name" item-value="iso2">
    <template slot="item" slot-scope="data">
      <div>
        <div :class="['flag-icon', `flag-icon-${data.item.iso2.toLowerCase()}`, 'mr-2']"></div>&nbsp;{{ data.item.name }}
      </div>
    </template>
    <template slot="selection" slot-scope="data">
      <div>
        <div :class="['flag-icon', `flag-icon-${data.item.iso2.toLowerCase()}`, 'mr-2']"></div>&nbsp;{{ data.item.name }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import countryHelpers from '../utils/country-helpers';

export default {
  props: {
    value: String
  },
  data() {
    return {
      countries: countryHelpers.getAllData(),
      selectedValue: this.value
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', this.selectedValue)
    }
  }
}
</script>

<style>
@import '../../node_modules/flag-icon-css/css/flag-icon.min.css';
</style>
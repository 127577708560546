<template>
  <v-card outlined>  
    <v-card-title>Assets and Liabilities</v-card-title>
    <v-card-text>
      <div v-if="selectedMethod === 'online'">
        <h2 class="title">Assets</h2>
        <assets-liabilities-readonly-table :data="onlineEntryData.assets" type="Asset"/>
        <br/>
        <h2 class="title">Liabilities</h2>
        <assets-liabilities-readonly-table :data="onlineEntryData.liabilities" type="Liability"/>
      </div>
      <div v-else-if="selectedMethod === 'upload'">
        <ul>
          <li v-for="file in spreadsheetFiles" :key="file.name">{{ file.name }}</li>
        </ul>
      </div>
      <div v-else>
        <p>You have not provided any information about your assets and liabilities. We will need a statement for the last 3 years detailing your South African Assets &amp; Liabilities which can be emailed to us along with any other outstanding documentation.</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AssetsLiabilitiesReadonlyTable from './AssetsLiabilitiesReadonlyTable';

export default {
  components: {
    AssetsLiabilitiesReadonlyTable
  },
  computed: {
    selectedMethod() { return this.$store.state.assetsAndLiabilities.selectedMethod; },
    onlineEntryData() { return this.$store.state.assetsAndLiabilities.onlineEntryData; },
    spreadsheetFiles() { return this.$store.state.assetsAndLiabilities.spreadsheetFiles; }
  }
}
</script>
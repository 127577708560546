import Vue from 'vue';
import Vuex from 'vuex';

import mainStore from './main-store';
import sourceOfFundFiles from './source-of-fund-files-store';
import assetsAndLiabilities from './assets-and-liabilities-store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    sourceOfFundFiles,
    assetsAndLiabilities
  },
  ...mainStore
});
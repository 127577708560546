<template>
  <div>
    <p>Please upload all files containing your assets and liabilities below:</p>
    <file-button multiple accept=".png,.jpg,.jpeg,.gif,.pdf,.doc,.docx,.odt,.xls,.xlsx,.zip" v-model="spreadsheetFiles">Upload</file-button>
  </div>
</template>

<script>
import FileButton from '../../../FileButton';

export default {
  components: {
    FileButton
  },
  computed: {
    spreadsheetFiles: {
      get() { return this.$store.state.assetsAndLiabilities.spreadsheetFiles; },
      set(value) { this.$store.commit('assetsAndLiabilities/changeSpreadsheetFiles', value); }
    }
  }
}
</script>
<template>
  <div style="background-color: #f7f8fa">
    <page-container>
      <v-card flat tile :class="{
        'pa-1': $vuetify.breakpoint.smAndDown,
        'pa-2': $vuetify.breakpoint.mdAndUp,
        'my-12': $vuetify.breakpoint.mdAndUp,
        'my-6': $vuetify.breakpoint.smAndDown,
        'mx-6': $vuetify.breakpoint.mdAndUp,
        'mx-3': $vuetify.breakpoint.smAndDown
      }">
        <v-container>
          <slot></slot>
        </v-container>
      </v-card>
    </page-container>
  </div>
</template>

<script>
import PageContainer from './PageContainer';

export default {
  components: {
    PageContainer
  }
}
</script>
<template>
  <v-expansion-panels accordian tile popout multiple v-model="sourceOfFundsSelected">
    <v-expansion-panel v-for="(source, index) in sourceOfFundsToRender" :key="source.name">
      <v-expansion-panel-header :hide-actions="true">
        <v-checkbox hide-details class="ma-0" color="primary" :value="sourceOfFundsSelected.includes(index)" readonly/>
        <div class="mt-1 font-weight-bold">{{ source.displayName || source.name }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <source-of-funds-section :source-of-fund="source.name"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header :hide-actions="true">
        <v-checkbox hide-details class="ma-0" color="primary" :value="sourceOfFundsSelected.includes(6)" readonly/>
        <div class="mt-1 font-weight-bold">Other</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <other-source-of-funds-section/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import SourceOfFundsSection from './SourceOfFundsSection';
import OtherSourceOfFundsSection from './OtherSourceOfFundsSection';

export default {
  components: {
    SourceOfFundsSection,
    OtherSourceOfFundsSection
  },
  props: {
    value: {
      type: Array,
      default: new Array()
    }
  },
  data() {
    return {
      sourceOfFundsSelected: [],
      sourceOfFunds: [
        {
          displayName: 'Loan',
          name: 'loan'
        },
        {
          displayName: 'Inheritance',
          name: 'inheritance'
        },
        {
          displayName: 'Sale of Property',
          name: 'saleOfProperty'
        },
        {
          displayName: 'Donation',
          name: 'donation'
        },
        { 
          displayName: 'Shares',
          name: 'shares'
        },
        {
          displayName: "Savings/Cash/Bank Account",
          name: 'bankAccount'
        },
        {
          displayName: 'Other',
          name: 'other'
        }
      ],
    }
  },
  computed: {
    sourceOfFundsToRender() { 
      return this.sourceOfFunds.filter(x => x.name !== 'other');
    }
  },
  watch: {
    sourceOfFundsSelected: function(newSourceOfFundsSelected) {
      const selectedSources = [];

      const sourceOfFundsWithOther = [
        ...this.sourceOfFunds,
        {
          name: 'other'
        }
      ]

      for (const selectedIndex of newSourceOfFundsSelected) {
        selectedSources.push(sourceOfFundsWithOther[selectedIndex].name);
      }

      this.$emit('input', selectedSources);
    }
  }
}
</script>
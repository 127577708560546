<template>
  <v-card outlined>  
    <v-card-title>Foreign Investment Details</v-card-title>
    <v-card-text>
      <p>
        <b>Investment Amount:</b> {{ foreignInvestmentAmount }}<br/>
        <b>Type of Investment:</b> {{ foreignInvestmentType }}<br/>
        <b>Country of Investment:</b> {{ foreignInvestmentCountry }}<br/>
        <b>Are you currently a beneficiary of a local or foreign Trust?:</b> {{ beneficiaryOfTrust }}<br/>
        <b>Have you made any loans to a local or foreign Trust?:</b> {{ existingLoanToTrust }}<br/>
        <b>Do you hold a direct or indirect shareholding of 20% or more in any local or foreign legal entity?:</b> {{ shareholding20OrMore }}<br/>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import currencyFormatter from '../../../../utils/currency-formatter';
import countryHelpers from '../../../../utils/country-helpers';

export default {
  computed: {
    foreignInvestmentAmount() { return currencyFormatter.format(this.$store.state.investmentAmount, 'R'); },
    foreignInvestmentType() { return this.$store.state.investmentType; },
    foreignInvestmentCountry() { 
      const code = this.$store.state.investmentCountry; 

      return (countryHelpers.getCountryByIso2Code(code) || { name: '' }).name;
    },
    beneficiaryOfTrust() {return this.$store.state.beneficiaryOfTrust;},
    existingLoanToTrust() {return this.$store.state.existingLoanToTrust},
    shareholding20OrMore() {return this.$store.state.shareholding20OrMore}
  }
}
</script>
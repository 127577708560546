const countryData = [
  {
    "name": "Afghanistan",
    "iso2": "AF",
    "iso3": "AFG",
    "phoneCode": "+93"
  },
  {
    "name": "Albania",
    "iso2": "AL",
    "iso3": "ALB",
    "phoneCode": "+355"
  },
  {
    "name": "Algeria",
    "iso2": "DZ",
    "iso3": "DZA",
    "phoneCode": "+213"
  },
  {
    "name": "American Samoa",
    "iso2": "AS",
    "iso3": "ASM",
    "phoneCode": "+1"
  },
  {
    "name": "Andorra",
    "iso2": "AD",
    "iso3": "AND",
    "phoneCode": "+376"
  },
  {
    "name": "Angola",
    "iso2": "AO",
    "iso3": "AGO",
    "phoneCode": "+244"
  },
  {
    "name": "Anguilla",
    "iso2": "AI",
    "iso3": "AIA",
    "phoneCode": "+1"
  },
  {
    "name": "Antarctica",
    "iso2": "AQ",
    "iso3": "ATA",
    "phoneCode": "+"
  },
  {
    "name": "Antigua and Barbuda",
    "iso2": "AG",
    "iso3": "ATG",
    "phoneCode": "+1"
  },
  {
    "name": "Argentina",
    "iso2": "AR",
    "iso3": "ARG",
    "phoneCode": "+54"
  },
  {
    "name": "Armenia",
    "iso2": "AM",
    "iso3": "ARM",
    "phoneCode": "+374"
  },
  {
    "name": "Aruba",
    "iso2": "AW",
    "iso3": "ABW",
    "phoneCode": "+297"
  },
  {
    "name": "Australia",
    "iso2": "AU",
    "iso3": "AUS",
    "phoneCode": "+61"
  },
  {
    "name": "Austria",
    "iso2": "AT",
    "iso3": "AUT",
    "phoneCode": "+43"
  },
  {
    "name": "Azerbaijan",
    "iso2": "AZ",
    "iso3": "AZE",
    "phoneCode": "+994"
  },
  {
    "name": "Bahamas",
    "iso2": "BS",
    "iso3": "BHS",
    "phoneCode": "+1"
  },
  {
    "name": "Bahrain",
    "iso2": "BH",
    "iso3": "BHR",
    "phoneCode": "+973"
  },
  {
    "name": "Bangladesh",
    "iso2": "BD",
    "iso3": "BGD",
    "phoneCode": "+880"
  },
  {
    "name": "Barbados",
    "iso2": "BB",
    "iso3": "BRB",
    "phoneCode": "+1"
  },
  {
    "name": "Belarus",
    "iso2": "BY",
    "iso3": "BLR",
    "phoneCode": "+375"
  },
  {
    "name": "Belgium",
    "iso2": "BE",
    "iso3": "BEL",
    "phoneCode": "+32"
  },
  {
    "name": "Belize",
    "iso2": "BZ",
    "iso3": "BLZ",
    "phoneCode": "+501"
  },
  {
    "name": "Benin",
    "iso2": "BJ",
    "iso3": "BEN",
    "phoneCode": "+229"
  },
  {
    "name": "Bermuda",
    "iso2": "BM",
    "iso3": "BMU",
    "phoneCode": "+1"
  },
  {
    "name": "Bhutan",
    "iso2": "BT",
    "iso3": "BTN",
    "phoneCode": "+975"
  },
  {
    "name": "Bolivia",
    "iso2": "BO",
    "phoneCode": "+591"
  },
  {
    "name": "Bosnia and Herzegovina",
    "iso2": "BA",
    "iso3": "BIH",
    "phoneCode": "+387"
  },
  {
    "name": "Botswana",
    "iso2": "BW",
    "iso3": "BWA",
    "phoneCode": "+267"
  },
  {
    "name": "Brazil",
    "iso2": "BR",
    "iso3": "BRA",
    "phoneCode": "+55"
  },
  {
    "name": "British Indian Ocean Territory",
    "iso2": "IO",
    "iso3": "IOT",
    "phoneCode": "+246"
  },
  {
    "name": "British Virgin Islands",
    "iso2": "VG",
    "phoneCode": "+1"
  },
  {
    "name": "Brunei",
    "iso2": "BN",
    "phoneCode": "+673"
  },
  {
    "name": "Bulgaria",
    "iso2": "BG",
    "iso3": "BGR",
    "phoneCode": "+359"
  },
  {
    "name": "Burkina Faso",
    "iso2": "BF",
    "iso3": "BFA",
    "phoneCode": "+226"
  },
  {
    "name": "Burma (Myanmar)",
    "iso2": "MM",
    "phoneCode": "+95"
  },
  {
    "name": "Burundi",
    "iso2": "BI",
    "iso3": "BDI",
    "phoneCode": "+257"
  },
  {
    "name": "Cambodia",
    "iso2": "KH",
    "iso3": "KHM",
    "phoneCode": "+855"
  },
  {
    "name": "Cameroon",
    "iso2": "CM",
    "iso3": "CMR",
    "phoneCode": "+237"
  },
  {
    "name": "Canada",
    "iso2": "CA",
    "iso3": "CAN",
    "phoneCode": "+1"
  },
  {
    "name": "Cape Verde",
    "iso2": "CV",
    "phoneCode": "+238"
  },
  {
    "name": "Cayman Islands",
    "iso2": "KY",
    "iso3": "CYM",
    "phoneCode": "+1"
  },
  {
    "name": "Central African Republic",
    "iso2": "CF",
    "iso3": "CAF",
    "phoneCode": "+236"
  },
  {
    "name": "Chad",
    "iso2": "TD",
    "iso3": "TCD",
    "phoneCode": "+"
  },
  {
    "name": "Chile",
    "iso2": "CL",
    "iso3": "CHL",
    "phoneCode": "+56"
  },
  {
    "name": "China",
    "iso2": "CN",
    "iso3": "CHN",
    "phoneCode": "+86"
  },
  {
    "name": "Christmas Island",
    "iso2": "CX",
    "iso3": "CXR",
    "phoneCode": "+"
  },
  {
    "name": "Cocos (Keeling) Islands",
    "iso2": "CC",
    "iso3": "CCK",
    "phoneCode": "+"
  },
  {
    "name": "Colombia",
    "iso2": "CO",
    "iso3": "COL",
    "phoneCode": "+57"
  },
  {
    "name": "Comoros",
    "iso2": "KM",
    "iso3": "COM",
    "phoneCode": "+269"
  },
  {
    "name": "Cook Islands",
    "iso2": "CK",
    "iso3": "COK",
    "phoneCode": "+682"
  },
  {
    "name": "Costa Rica",
    "iso2": "CR",
    "iso3": "",
    "phoneCode": "+506"
  },
  {
    "name": "Croatia",
    "iso2": "HR",
    "iso3": "HRV",
    "phoneCode": "+385"
  },
  {
    "name": "Cuba",
    "iso2": "CU",
    "iso3": "CUB",
    "phoneCode": "+53"
  },
  {
    "name": "Cyprus",
    "iso2": "CY",
    "iso3": "CYP",
    "phoneCode": "+357"
  },
  {
    "name": "Czech Republic",
    "iso2": "CZ",
    "phoneCode": "+420"
  },
  {
    "name": "Democratic Republic of the Congo",
    "iso2": "CD",
    "phoneCode": "+"
  },
  {
    "name": "Denmark",
    "iso2": "DK",
    "iso3": "DNK",
    "phoneCode": "+45"
  },
  {
    "name": "Djibouti",
    "iso2": "DJ",
    "iso3": "DJI",
    "phoneCode": "+253"
  },
  {
    "name": "Dominica",
    "iso2": "DM",
    "iso3": "DMA",
    "phoneCode": "+1"
  },
  {
    "name": "Dominican Republic",
    "iso2": "DO",
    "iso3": "DOM",
    "phoneCode": "+1"
  },
  {
    "name": "Ecuador",
    "iso2": "EC",
    "iso3": "ECU",
    "phoneCode": "+593"
  },
  {
    "name": "Egypt",
    "iso2": "EG",
    "iso3": "EGY",
    "phoneCode": "+20"
  },
  {
    "name": "El Salvador",
    "iso2": "SV",
    "iso3": "SLV",
    "phoneCode": "+503"
  },
  {
    "name": "Equatorial Guinea",
    "iso2": "GQ",
    "iso3": "GNQ",
    "phoneCode": "+240"
  },
  {
    "name": "Eritrea",
    "iso2": "ER",
    "iso3": "ERI",
    "phoneCode": "+291"
  },
  {
    "name": "Estonia",
    "iso2": "EE",
    "iso3": "EST",
    "phoneCode": "+372"
  },
  {
    "name": "Ethiopia",
    "iso2": "ET",
    "iso3": "ETH",
    "phoneCode": "+251"
  },
  {
    "name": "Falkland Islands",
    "iso2": "FK",
    "phoneCode": "+500"
  },
  {
    "name": "Faroe Islands",
    "iso2": "FO",
    "iso3": "FRO",
    "phoneCode": "+298"
  },
  {
    "name": "Fiji",
    "iso2": "FJ",
    "iso3": "FJI",
    "phoneCode": "+679"
  },
  {
    "name": "Finland",
    "iso2": "FI",
    "iso3": "FIN",
    "phoneCode": "+358"
  },
  {
    "name": "France",
    "iso2": "FR",
    "iso3": "FRA",
    "phoneCode": "+33"
  },
  {
    "name": "French Polynesia",
    "iso2": "PF",
    "iso3": "PYF",
    "phoneCode": "+689"
  },
  {
    "name": "Gabon",
    "iso2": "GA",
    "iso3": "GAB",
    "phoneCode": "+241"
  },
  {
    "name": "Gambia",
    "iso2": "GM",
    "iso3": "GMB",
    "phoneCode": "+220"
  },
  {
    "name": "Gaza Strip",
    "iso2": "XX",
    "iso3": "",
    "phoneCode": "+"
  },
  {
    "name": "Georgia",
    "iso2": "GE",
    "iso3": "GEO",
    "phoneCode": "+995"
  },
  {
    "name": "Germany",
    "iso2": "DE",
    "iso3": "DEU",
    "phoneCode": "+49"
  },
  {
    "name": "Ghana",
    "iso2": "GH",
    "iso3": "GHA",
    "phoneCode": "+233"
  },
  {
    "name": "Gibraltar",
    "iso2": "GI",
    "iso3": "GIB",
    "phoneCode": "+350"
  },
  {
    "name": "Greece",
    "iso2": "GR",
    "iso3": "GRC",
    "phoneCode": "+30"
  },
  {
    "name": "Greenland",
    "iso2": "GL",
    "iso3": "GRL",
    "phoneCode": "+299"
  },
  {
    "name": "Grenada",
    "iso2": "GD",
    "iso3": "GRD",
    "phoneCode": "+1"
  },
  {
    "name": "Guam",
    "iso2": "GU",
    "iso3": "GUM",
    "phoneCode": "+1"
  },
  {
    "name": "Guatemala",
    "iso2": "GT",
    "iso3": "GTM",
    "phoneCode": "+502"
  },
  {
    "name": "Guernsey",
    "iso2": "GG",
    "iso3": "GGY",
    "phoneCode": "+44"
  },
  {
    "name": "Guinea",
    "iso2": "GN",
    "iso3": "GIN",
    "phoneCode": "+224"
  },
  {
    "name": "Guinea-Bissau",
    "iso2": "GW",
    "iso3": "GNB",
    "phoneCode": "+245"
  },
  {
    "name": "Guyana",
    "iso2": "GY",
    "iso3": "GUY",
    "phoneCode": "+592"
  },
  {
    "name": "Haiti",
    "iso2": "HT",
    "iso3": "HTI",
    "phoneCode": "+509"
  },
  {
    "name": "Holy See (Vatican City)",
    "iso2": "VA",
    "phoneCode": "+39"
  },
  {
    "name": "Honduras",
    "iso2": "HN",
    "iso3": "HND",
    "phoneCode": "+504"
  },
  {
    "name": "Hong Kong",
    "iso2": "HK",
    "iso3": "HKG",
    "phoneCode": "+852"
  },
  {
    "name": "Hungary",
    "iso2": "HU",
    "iso3": "HUN",
    "phoneCode": "+36"
  },
  {
    "name": "Iceland",
    "iso2": "IS",
    "iso3": "ISL",
    "phoneCode": "+354"
  },
  {
    "name": "India",
    "iso2": "IN",
    "iso3": "IND",
    "phoneCode": "+91"
  },
  {
    "name": "Indonesia",
    "iso2": "ID",
    "iso3": "IDN",
    "phoneCode": "+62"
  },
  {
    "name": "Iran",
    "iso2": "IR",
    "phoneCode": "+98"
  },
  {
    "name": "Iraq",
    "iso2": "IQ",
    "iso3": "IRQ",
    "phoneCode": "+964"
  },
  {
    "name": "Ireland",
    "iso2": "IE",
    "iso3": "IRL",
    "phoneCode": "+353"
  },
  {
    "name": "Isle of Man",
    "iso2": "IM",
    "iso3": "IMN",
    "phoneCode": "+44"
  },
  {
    "name": "Israel",
    "iso2": "IL",
    "iso3": "ISR",
    "phoneCode": "+972"
  },
  {
    "name": "Italy",
    "iso2": "IT",
    "iso3": "ITA",
    "phoneCode": "+39"
  },
  {
    "name": "Ivory Coast",
    "iso2": "CI",
    "phoneCode": "+225"
  },
  {
    "name": "Jamaica",
    "iso2": "JM",
    "iso3": "JAM",
    "phoneCode": "+1"
  },
  {
    "name": "Japan",
    "iso2": "JP",
    "iso3": "JPN",
    "phoneCode": "+81"
  },
  {
    "name": "Jersey",
    "iso2": "JE",
    "iso3": "JEY",
    "phoneCode": "+44"
  },
  {
    "name": "Jordan",
    "iso2": "JO",
    "iso3": "JOR",
    "phoneCode": "+962"
  },
  {
    "name": "Kazakhstan",
    "iso2": "KZ",
    "iso3": "KAZ",
    "phoneCode": "+7"
  },
  {
    "name": "Kenya",
    "iso2": "KE",
    "iso3": "KEN",
    "phoneCode": "+254"
  },
  {
    "name": "Kiribati",
    "iso2": "KI",
    "iso3": "KIR",
    "phoneCode": "+686"
  },
  {
    "name": "Kosovo",
    "iso2": "/",
    "iso3": "",
    "phoneCode": "+"
  },
  {
    "name": "Kuwait",
    "iso2": "KW",
    "iso3": "KWT",
    "phoneCode": "+965"
  },
  {
    "name": "Kyrgyzstan",
    "iso2": "KG",
    "iso3": "KGZ",
    "phoneCode": "+996"
  },
  {
    "name": "Laos",
    "iso2": "LA",
    "phoneCode": "+856"
  },
  {
    "name": "Latvia",
    "iso2": "LV",
    "iso3": "LVA",
    "phoneCode": "+371"
  },
  {
    "name": "Lebanon",
    "iso2": "LB",
    "iso3": "LBN",
    "phoneCode": "+961"
  },
  {
    "name": "Lesotho",
    "iso2": "LS",
    "iso3": "LSO",
    "phoneCode": "+266"
  },
  {
    "name": "Liberia",
    "iso2": "LR",
    "iso3": "LBR",
    "phoneCode": "+231"
  },
  {
    "name": "Libya",
    "iso2": "LY",
    "iso3": "LBY",
    "phoneCode": "+218"
  },
  {
    "name": "Liechtenstein",
    "iso2": "LI",
    "iso3": "LIE",
    "phoneCode": "+423"
  },
  {
    "name": "Lithuania",
    "iso2": "LT",
    "iso3": "LTU",
    "phoneCode": "+370"
  },
  {
    "name": "Luxembourg",
    "iso2": "LU",
    "iso3": "LUX",
    "phoneCode": "+352"
  },
  {
    "name": "Macau",
    "iso2": "MO",
    "phoneCode": "+853"
  },
  {
    "name": "Macedonia",
    "iso2": "MK",
    "phoneCode": "+389"
  },
  {
    "name": "Madagascar",
    "iso2": "MG",
    "iso3": "MDG",
    "phoneCode": "+261"
  },
  {
    "name": "Malawi",
    "iso2": "MW",
    "iso3": "MWI",
    "phoneCode": "+265"
  },
  {
    "name": "Malaysia",
    "iso2": "MY",
    "iso3": "MYS",
    "phoneCode": "+60"
  },
  {
    "name": "Maldives",
    "iso2": "MV",
    "iso3": "MDV",
    "phoneCode": "+960"
  },
  {
    "name": "Mali",
    "iso2": "ML",
    "iso3": "MLI",
    "phoneCode": "+223"
  },
  {
    "name": "Malta",
    "iso2": "MT",
    "iso3": "MLT",
    "phoneCode": "+356"
  },
  {
    "name": "Marshall Islands",
    "iso2": "MH",
    "iso3": "MHL",
    "phoneCode": "+692"
  },
  {
    "name": "Mauritania",
    "iso2": "MR",
    "iso3": "MRT",
    "phoneCode": "+222"
  },
  {
    "name": "Mauritius",
    "iso2": "MU",
    "iso3": "MUS",
    "phoneCode": "+230"
  },
  {
    "name": "Mayotte",
    "iso2": "YT",
    "iso3": "MYT",
    "phoneCode": "+262"
  },
  {
    "name": "Mexico",
    "iso2": "MX",
    "iso3": "MEX",
    "phoneCode": "+52"
  },
  {
    "name": "Micronesia",
    "iso2": "FM",
    "phoneCode": "+691"
  },
  {
    "name": "Moldova",
    "iso2": "MD",
    "phoneCode": "+373"
  },
  {
    "name": "Monaco",
    "iso2": "MC",
    "iso3": "MCO",
    "phoneCode": "+377"
  },
  {
    "name": "Mongolia",
    "iso2": "MN",
    "iso3": "MNG",
    "phoneCode": "+976"
  },
  {
    "name": "Montenegro",
    "iso2": "ME",
    "iso3": "MNE",
    "phoneCode": "+382"
  },
  {
    "name": "Montserrat",
    "iso2": "MS",
    "iso3": "MSR",
    "phoneCode": "+1"
  },
  {
    "name": "Morocco",
    "iso2": "MA",
    "iso3": "MAR",
    "phoneCode": "+212"
  },
  {
    "name": "Mozambique",
    "iso2": "MZ",
    "iso3": "MOZ",
    "phoneCode": "+258"
  },
  {
    "name": "Myanmar",
    "iso2": "MM",
    "iso3": "MMR",
    "phoneCode": "+"
  },
  {
    "name": "Namibia",
    "iso2": "NA",
    "iso3": "NAM",
    "phoneCode": "+264"
  },
  {
    "name": "Nauru",
    "iso2": "NR",
    "iso3": "NRU",
    "phoneCode": "+674"
  },
  {
    "name": "Nepal",
    "iso2": "NP",
    "iso3": "NPL",
    "phoneCode": "+977"
  },
  {
    "name": "Netherlands",
    "iso2": "NL",
    "iso3": "NLD",
    "phoneCode": "+31"
  },
  {
    "name": "Netherlands Antilles",
    "iso2": "AN",
    "phoneCode": "+599"
  },
  {
    "name": "New Caledonia",
    "iso2": "NC",
    "iso3": "NCL",
    "phoneCode": "+687"
  },
  {
    "name": "New Zealand",
    "iso2": "NZ",
    "iso3": "NZL",
    "phoneCode": "+64"
  },
  {
    "name": "Nicaragua",
    "iso2": "NI",
    "iso3": "NIC",
    "phoneCode": "+505"
  },
  {
    "name": "Niger",
    "iso2": "NE",
    "iso3": "NER",
    "phoneCode": "+227"
  },
  {
    "name": "Nigeria",
    "iso2": "NG",
    "iso3": "NGA",
    "phoneCode": "+234"
  },
  {
    "name": "Niue",
    "iso2": "NU",
    "iso3": "NIU",
    "phoneCode": "+683"
  },
  {
    "name": "Norfolk Island",
    "iso2": "/ ",
    "iso3": "NFK",
    "phoneCode": "+"
  },
  {
    "name": "North Korea",
    "iso2": "KP",
    "phoneCode": "+850"
  },
  {
    "name": "Northern Mariana Islands",
    "iso2": "MP",
    "iso3": "MNP",
    "phoneCode": "+1"
  },
  {
    "name": "Norway",
    "iso2": "NO",
    "iso3": "NOR",
    "phoneCode": "+47"
  },
  {
    "name": "Oman",
    "iso2": "OM",
    "iso3": "OMN",
    "phoneCode": "+968"
  },
  {
    "name": "Pakistan",
    "iso2": "PK",
    "iso3": "PAK",
    "phoneCode": "+92"
  },
  {
    "name": "Palau",
    "iso2": "PW",
    "iso3": "PLW",
    "phoneCode": "+680"
  },
  {
    "name": "Palestine",
    "iso2": "PS",
    "iso3": "PSE",
    "phoneCode": "+970"
  },
  {
    "name": "Panama",
    "iso2": "PA",
    "iso3": "PAN",
    "phoneCode": "+507"
  },
  {
    "name": "Papua New Guinea",
    "iso2": "PG",
    "iso3": "PNG",
    "phoneCode": "+675"
  },
  {
    "name": "Paraguay",
    "iso2": "PY",
    "iso3": "PRY",
    "phoneCode": "+595"
  },
  {
    "name": "Peru",
    "iso2": "PE",
    "iso3": "PER",
    "phoneCode": "+51"
  },
  {
    "name": "Philippines",
    "iso2": "PH",
    "iso3": "PHL",
    "phoneCode": "+63"
  },
  {
    "name": "Pitcairn Islands",
    "iso2": "PN",
    "phoneCode": "+"
  },
  {
    "name": "Poland",
    "iso2": "PL",
    "iso3": "POL",
    "phoneCode": "+48"
  },
  {
    "name": "Portugal",
    "iso2": "PT",
    "iso3": "PRT",
    "phoneCode": "+351"
  },
  {
    "name": "Puerto Rico",
    "iso2": "PR",
    "iso3": "PRI",
    "phoneCode": "+1"
  },
  {
    "name": "Qatar",
    "iso2": "QA",
    "iso3": "QAT",
    "phoneCode": "+974"
  },
  {
    "name": "Republic of the Congo",
    "iso2": "CG",
    "iso3": "",
    "phoneCode": "+242"
  },
  {
    "name": "Romania",
    "iso2": "RO",
    "iso3": "ROU",
    "phoneCode": "+40"
  },
  {
    "name": "Russia",
    "iso2": "RU",
    "iso3": "RUS",
    "phoneCode": "+7"
  },
  {
    "name": "Rwanda",
    "iso2": "RW",
    "iso3": "RWA",
    "phoneCode": "+250"
  },
  {
    "name": "Saint Barthelemy",
    "iso2": "BL",
    "phoneCode": "+590"
  },
  {
    "name": "Saint Helena",
    "iso2": "SH",
    "phoneCode": "+290"
  },
  {
    "name": "Saint Kitts and Nevis",
    "iso2": "KN",
    "iso3": "KNA",
    "phoneCode": "+1"
  },
  {
    "name": "Saint Lucia",
    "iso2": "LC",
    "iso3": "LCA",
    "phoneCode": "+1"
  },
  {
    "name": "Saint Martin",
    "iso2": "MF",
    "iso3": "",
    "phoneCode": "+590"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "iso2": "PM",
    "iso3": "SPM",
    "phoneCode": "+508"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "iso2": "VC",
    "iso3": "VCT",
    "phoneCode": "+1"
  },
  {
    "name": "Samoa",
    "iso2": "WS",
    "iso3": "WSM",
    "phoneCode": "+685"
  },
  {
    "name": "San Marino",
    "iso2": "SM",
    "iso3": "SMR",
    "phoneCode": "+378"
  },
  {
    "name": "Sao Tome and Principe",
    "iso2": "ST",
    "iso3": "STP",
    "phoneCode": "+239"
  },
  {
    "name": "Saudi Arabia",
    "iso2": "SA",
    "iso3": "SAU",
    "phoneCode": "+966"
  },
  {
    "name": "Senegal",
    "iso2": "SN",
    "iso3": "SEN",
    "phoneCode": "+221"
  },
  {
    "name": "Serbia",
    "iso2": "RS",
    "iso3": "SRB",
    "phoneCode": "+381"
  },
  {
    "name": "Seychelles",
    "iso2": "SC",
    "iso3": "SYC",
    "phoneCode": "+248"
  },
  {
    "name": "Sierra Leone",
    "iso2": "SL",
    "iso3": "SLE",
    "phoneCode": "+232"
  },
  {
    "name": "Singapore",
    "iso2": "SG",
    "iso3": "SGP",
    "phoneCode": "+65"
  },
  {
    "name": "Slovakia",
    "iso2": "SK",
    "iso3": "SVK",
    "phoneCode": "+421"
  },
  {
    "name": "Slovenia",
    "iso2": "SI",
    "iso3": "SVN",
    "phoneCode": "+386"
  },
  {
    "name": "Solomon Islands",
    "iso2": "SB",
    "iso3": "SLB",
    "phoneCode": "+677"
  },
  {
    "name": "Somalia",
    "iso2": "SO",
    "iso3": "SOM",
    "phoneCode": "+252"
  },
  {
    "name": "South Africa",
    "iso2": "ZA",
    "iso3": "ZAF",
    "phoneCode": "+27"
  },
  {
    "name": "South Korea",
    "iso2": "KR",
    "iso3": "KOR",
    "phoneCode": "+82"
  },
  {
    "name": "South Sudan",
    "iso2": "SS",
    "iso3": "SSD",
    "phoneCode": "+211"
  },
  {
    "name": "Spain",
    "iso2": "ES",
    "iso3": "ESP",
    "phoneCode": "+34"
  },
  {
    "name": "Sri Lanka",
    "iso2": "LK",
    "iso3": "LKA",
    "phoneCode": "+94"
  },
  {
    "name": "Sudan",
    "iso2": "SD",
    "iso3": "SDN",
    "phoneCode": "+249"
  },
  {
    "name": "Suriname",
    "iso2": "SR",
    "iso3": "SUR",
    "phoneCode": "+597"
  },
  {
    "name": "Svalbard",
    "iso2": "SJ",
    "phoneCode": "+"
  },
  {
    "name": "Swaziland",
    "iso2": "SZ",
    "iso3": "SWZ",
    "phoneCode": "+268"
  },
  {
    "name": "Sweden",
    "iso2": "SE",
    "iso3": "SWE",
    "phoneCode": "+46"
  },
  {
    "name": "Switzerland",
    "iso2": "CH",
    "iso3": "CHE",
    "phoneCode": "+41"
  },
  {
    "name": "Syria",
    "iso2": "SY",
    "phoneCode": "+963"
  },
  {
    "name": "Taiwan",
    "iso2": "TW",
    "phoneCode": "+886"
  },
  {
    "name": "Tajikistan",
    "iso2": "TJ",
    "iso3": "TJK",
    "phoneCode": "+992"
  },
  {
    "name": "Tanzania",
    "iso2": "TZ",
    "iso3": "TZA",
    "phoneCode": "+255"
  },
  {
    "name": "Thailand",
    "iso2": "TH",
    "iso3": "THA",
    "phoneCode": "+66"
  },
  {
    "name": "Timor-Leste",
    "iso2": "TL",
    "iso3": "TLS",
    "phoneCode": "+670"
  },
  {
    "name": "Togo",
    "iso2": "TG",
    "iso3": "TGO",
    "phoneCode": "+228"
  },
  {
    "name": "Tokelau",
    "iso2": "TK",
    "iso3": "TKL",
    "phoneCode": "+690"
  },
  {
    "name": "Tonga",
    "iso2": "TO",
    "iso3": "TON",
    "phoneCode": "+676"
  },
  {
    "name": "Trinidad and Tobago",
    "iso2": "TT",
    "iso3": "TTO",
    "phoneCode": "+1"
  },
  {
    "name": "Tunisia",
    "iso2": "TN",
    "iso3": "TUN",
    "phoneCode": "+216"
  },
  {
    "name": "Turkey",
    "iso2": "TR",
    "iso3": "TUR",
    "phoneCode": "+90"
  },
  {
    "name": "Turkmenistan",
    "iso2": "TM",
    "iso3": "TKM",
    "phoneCode": "+993"
  },
  {
    "name": "Turks and Caicos Islands",
    "iso2": "TC",
    "iso3": "TCA",
    "phoneCode": "+1"
  },
  {
    "name": "Tuvalu",
    "iso2": "TV",
    "iso3": "TUV",
    "phoneCode": "+688"
  },
  {
    "name": "Uganda",
    "iso2": "UG",
    "iso3": "UGA",
    "phoneCode": "+256"
  },
  {
    "name": "Ukraine",
    "iso2": "UA",
    "iso3": "UKR",
    "phoneCode": "+380"
  },
  {
    "name": "United Arab Emirates",
    "iso2": "AE",
    "iso3": "ARE",
    "phoneCode": "+971"
  },
  {
    "name": "United Kingdom",
    "iso2": "GB",
    "iso3": "GBR",
    "phoneCode": "+44"
  },
  {
    "name": "United States",
    "iso2": "US",
    "iso3": "USA",
    "phoneCode": "+1"
  },
  {
    "name": "Uruguay",
    "iso2": "UY",
    "iso3": "URY",
    "phoneCode": "+598"
  },
  {
    "name": "US Virgin Islands",
    "iso2": "VI",
    "phoneCode": "+1"
  },
  {
    "name": "Uzbekistan",
    "iso2": "UZ",
    "iso3": "UZB",
    "phoneCode": "+998"
  },
  {
    "name": "Vanuatu",
    "iso2": "VU",
    "iso3": "VUT",
    "phoneCode": "+678"
  },
  {
    "name": "Venezuela",
    "iso2": "VE",
    "phoneCode": "+58"
  },
  {
    "name": "Vietnam",
    "iso2": "VN",
    "iso3": "",
    "phoneCode": "+84"
  },
  {
    "name": "Wallis and Futuna",
    "iso2": "WF",
    "iso3": "WLF",
    "phoneCode": "+681"
  },
  {
    "name": "West Bank",
    "iso2": "#",
    "iso3": "",
    "phoneCode": "+"
  },
  {
    "name": "Western Sahara",
    "iso2": "EH",
    "iso3": "ESH",
    "phoneCode": "+"
  },
  {
    "name": "Yemen",
    "iso2": "YE",
    "iso3": "YEM",
    "phoneCode": "+967"
  },
  {
    "name": "Zambia",
    "iso2": "ZM",
    "iso3": "ZMB",
    "phoneCode": "+260"
  },
  {
    "name": "Zimbabwe",
    "iso2": "ZW",
    "iso3": "ZWE",
    "phoneCode": "+263"
  }
];

function getCountryByIso2Code(iso2Code) {
  const index = countryData.findIndex(c => c.iso2 === iso2Code);

  if (index >= 0) {
    return countryData[index];
  }

  return null;
}

function getCountryByIso3Code(iso3Code) {
  const index = countryData.findIndex(c => c.iso3 === iso3Code);

  if (index >= 0) {
    return countryData[index];
  }

  return null;
}

function getCountryByPhoneCode(phoneCode) {
  if (phoneCode[0] !== '+') phoneCode = '+' + phoneCode;

  const index = countryData.findIndex(c => c.phoneCode === phoneCode);

  if (index >= 0) {
    return countryData[index];
  }

  return null;
}

function getCountryByName(name) {
  const index = countryData.findIndex(c => c.name === name);

  if (index >= 0) {
    return countryData[index];
  }

  return null;
}

function getAllData() {
  return countryData;
}

export default {
  getCountryByIso2Code,
  getCountryByIso3Code,
  getCountryByPhoneCode,
  getCountryByName,
  getAllData
};
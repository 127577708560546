<template>
  <div>
    <p>Please enter your assets and liabilities in the tables below.</p>
    <h2 class="headline">Assets</h2>
    <assets-liabilities-table type="Asset" v-model="assetsTable" />
    <br/>
    <h2 class="headline">Liabilities</h2>
    <assets-liabilities-table type="Liability" v-model="liabilitiesTable" />
  </div>
</template>

<script>
import AssetsLiabilitiesTable from './AssetsLiabilitiesTable';

export default {
  components: {
    AssetsLiabilitiesTable
  },
  computed: {
    assetsTable: {
      get() { return this.$store.state.assetsAndLiabilities.onlineEntryData.assets; },
      set(value) { this.$store.commit('assetsAndLiabilities/changeAssetsOnlineEntryData', value); }
    },
    liabilitiesTable: {
      get() { return this.$store.state.assetsAndLiabilities.onlineEntryData.liabilities; },
      set(value) { this.$store.commit('assetsAndLiabilities/changeLiabilitiesOnlineEntryData', value); }
    }
  }
}
</script>
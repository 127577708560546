<template>
  <v-text-field v-bind="$attrs" v-model="amount" v-money="money"/>
</template>

<script>
import currencyFormatter from '../utils/currency-formatter';

import {VMoney} from 'v-money';

export default {
  props: {
    value: Number
  },
  data() { 
    return {
      amount: this.value,
      money: {
        decimal: '.', 
        thousands: ' ',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      }
    }
  },
  directives: {
    money: VMoney
  },
  watch: {
    amount(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.$emit('input', currencyFormatter.unformat(newValue));
      }
    }
  }
}
</script>
<template>
  <v-card outlined>  
    <v-card-title>Personal Details</v-card-title>
    <v-card-text>
      <p>
        <b>First Name:</b> {{ firstName }}<br/>
        <b>Middle Names:</b> {{ middleNames }}<br/>
        <b>Last Name:</b> {{ lastName }}<br/>
        <b>Identification Type:</b> {{ identificationType }}<br/>
        <b>{{ identificationType }} Number:</b> {{ identityNumber }}<br/>
        <b>{{ identificationType }} Document:</b> {{ identityDocument.name }}<br/>
        <b>South African Income Tax Number:</b> {{ incomeTaxNumber }}<br/>
        <b>Cellphone Number:</b> {{ cellphoneNumber }}<br/>
        <b>Email Address:</b> {{ emailAddress}}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    firstName() { return this.$store.state.firstName; },
    middleNames() { return this.$store.state.middleNames; },
    lastName() { return this.$store.state.lastName; },
    identificationType() { return this.$store.state.identificationType; },
    identityNumber() { return this.$store.state.identityNumber; },
    identityDocument() { return this.$store.state.identityDocument; },
    incomeTaxNumber() { return this.$store.state.incomeTaxNumber; },
    cellphoneNumber() { return this.$store.state.cellphoneNumber; },
    emailAddress() { return this.$store.state.emailAddress; }
  }
}
</script>
<template>
  <div>
    <v-text-field @input="handleInput" type="tel" :label="label" :outlined="outlined" :clearable="clearable" v-mask="telephoneMask" :value="telephoneNumber" :rules="rules" :required="required">
      <template slot="prepend-inner">
        <v-menu offset-y max-height="300px">
          <template v-slot:activator="{ on }">
            <div v-on="on" v-ripple>
              <span :class="['flag-icon', `flag-icon-${selectedCountry.iso2.toLowerCase()}`]"></span>
              <v-icon>mdi-menu-down</v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item v-for="country in countries" :key="country.code" @click="countrySelected(country)">
              <v-list-item-title>
                <span :class="['flag-icon', `flag-icon-${country.iso2.toLowerCase()}`]"></span>
                &nbsp; {{ country.name }} ({{ country.phoneCode }})
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
  
<script>
import "flag-icon-css/css/flag-icon.min.css";

import countryHelpers from '../utils/country-helpers';
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask
  },
  props: {
    label: String,
    outlined: Boolean,
    clearable: Boolean,
    value: String,
    rules: Array,
    required: Boolean
  },
  computed: {
    telephoneMask() {
      return `${this.selectedCountry.phoneCode} ############################################################`
    }
  },
  data() {
    const defaultCountry = countryHelpers.getCountryByIso2Code('ZA');

    return {
      countries: countryHelpers.getAllData(),
      selectedCountry: defaultCountry,
      telephoneNumber: this.value || defaultCountry.phoneCode
    };
  },
  methods: {
    countrySelected(country) {
      this.selectedCountry = country;

      this.handleInput(`${country.phoneCode} `);
    },
    handleInput(number) {
      this.telephoneNumber = number;

      this.$emit('input', this.telephoneNumber);
    }
  }
};
</script>

<style scoped>

</style>
<template>
  <v-form ref="foreignInvestmentDetailsForm">
    <v-row dense>
      <v-col cols="12">
        <currency-input label="Investment Amount*" outlined prefix="R" v-model="investmentAmount" :rules="rules.investmentAmount"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-text-field label="Type of Investment*" persistent-hint hint="For example Cash, Equities, Bonds, Property, etc" outlined v-model="investmentType"  :rules="rules.investmentType"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <country-select label="Country of Investment" outlined v-model="investmentCountry" :rules="rules.investmentCountry"/>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-radio-group label="Are you currently a beneficiary of a local or foreign Trust?" v-model="beneficiaryOfTrust"     :rules="rules.beneficiaryOfTrust"  row >
          <v-radio label="Yes" value="Yes"></v-radio>
          <v-radio label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-radio-group label="Have you made any loans to a local or foreign Trust?" v-model="existingLoanToTrust"    :rules="rules.existingLoanToTrust"  row >
          <v-radio label="Yes" value="Yes"></v-radio>
          <v-radio label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-radio-group label="Do you hold a direct or indirect shareholding of 20% or more in any local or foreign legal entity?" v-model="shareholding20OrMore" :rules="rules.shareholding20OrMore"  row >
          <v-radio label="Yes" value="Yes"></v-radio>
          <v-radio label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <br/>
    <v-btn text @click="$emit('back')" rounded><v-icon left>mdi-arrow-left</v-icon>&nbsp;Back</v-btn>
    &nbsp;
    <v-btn color="primary" @click="nextClick" rounded>Next&nbsp;<v-icon right>mdi-arrow-right</v-icon></v-btn>
  </v-form>
</template>

<script>
import CountrySelect from '../../CountrySelect';
import CurrencyInput from '../../CurrencyInput';

export default {
  components: {
    CountrySelect,
    CurrencyInput,
  },
  data() { 
    return {
      rules: {
        investmentAmount: [ 
          v => !!v || 'Investment Amount is required'
        ],
        investmentType: [ v => !!v || 'Investment Type is required' ],
        investmentCountry: [ v => !!v || 'Investment Country is required' ],
        existingLoanToTrust: [ v => !!v || 'Existing Loan to trust is required' ],
        shareholding20OrMore: [ v => !!v || 'Shareholdning 20% or more is required' ],
        beneficiaryOfTrust: [ v => !!v || 'Beneficiary of trust is required' ],
      }
    }
  },
  computed: {
    investmentAmount: {
      get() { return this.$store.state.investmentAmount },
      set(value) { this.$store.commit('changeInvestmentAmount', value) }
    },
    investmentType: {
      get() { return this.$store.state.investmentType },
      set(value) { this.$store.commit('changeInvestmentType', value) }
    },
    investmentCountry: {
      get() { return this.$store.state.investmentCountry },
      set(value) { this.$store.commit('changeInvestmentCountry', value) }
    },
    existingLoanToTrust: {
      get() { return this.$store.state.existingLoanToTrust },
      set(value) { this.$store.commit('changeExistingLoanToTrust', value) }
    },
    shareholding20OrMore: {
      get() { return this.$store.state.shareholding20OrMore },
      set(value) { this.$store.commit('changeShareholding20OrMore', value) }
    },
    beneficiaryOfTrust: {
      get() { return this.$store.state.beneficiaryOfTrust },
      set(value) { this.$store.commit('changeBeneficiaryOfTrust', value) }
    },
  },
  methods: {
    nextClick() {
      const formData = {
      investmentAmount: this.investmentAmount,
      investmentType: this.investmentType,
      investmentCountry: this.investmentCountry,
      existingLoanToTrust: this.existingLoanToTrust,
      shareholding20OrMore: this.shareholding20OrMore,
      beneficiaryOfTrust: this.beneficiaryOfTrust,
    };

      if(this.$refs.foreignInvestmentDetailsForm.validate()) {
        this.$emit('next');
      }
    }
  }
}
</script>
<template>
  <v-card outlined>  
    <v-card-title>Source of Funds</v-card-title>
    <v-card-text>
      <div v-for="sourceOfFund in sourceOfFunds" :key="sourceOfFund.name">
        <p>
          <b>{{ sourceOfFund.displayName }}</b>
          <ul>
            <li v-for="(files, description) in sourceOfFundFiles[sourceOfFund.name]" v-if="files.length > 0" :key="description">
              {{ description }}
              <ul>
                <li v-for="file in files" :key="file.name">{{ file.name }}</li>
              </ul>
            </li>
          </ul>
        </p>
      </div>
      
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    sourceOfFunds() { 
      const selectedSourceOfFunds = this.$store.state.sourceOfFunds; 
      return selectedSourceOfFunds.map(x => {
        if (x === "loan") return { name: x, displayName: "Loan" }
        else if(x === "inheritance") return { name: x, displayName:  "Inheritance" }
        else if (x === "saleOfProperty") return { name: x, displayName:  "Sale of Property" }
        else if (x === "donation") return { name: x, displayName:  "Donation" }
        else if (x === "shares") return { name: x, displayName:  "Shares" }
        else if (x === "bankAccount") return { name: x, displayName:  "Savings/Cash/Bank Account" }
        else if (x === "other") return { name: x, displayName:  "Other" }
      })
    },
    sourceOfFundFiles() { return this.$store.state.sourceOfFundFiles; }
  }
}
</script>
import Vue from 'vue';

Vue.directive('border-top-left-radius', {
  bind: function(el, binding) {
    el.style.borderTopLeftRadius = binding.value;
  }
});

Vue.directive('border-top-right-radius', {
  bind: function(el, binding) {
    el.style.borderTopRightRadius = binding.value;
  }
});

Vue.directive('border-bottom-left-radius', {
  bind: function(el, binding) {
    el.style.borderBottomLeftRadius = binding.value;
  }
});

Vue.directive('border-bottom-right-radius', {
  bind: function(el, binding) {
    el.style.borderBottomRightRadius = binding.value;
  }
});

Vue.directive('border-radius', {
  bind: function(el, binding) {
    el.style.borderRadius = binding.value;
  }
});
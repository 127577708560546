<template>
  <v-form ref="personalDetailsForm">
    <v-row dense>
      <v-col cols="12" md="4">
        <v-text-field label="First Name*" required outlined v-model="firstName" :rules="rules.firstName"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Middle Names" required outlined v-model="middleNames"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Last Name*" required outlined v-model="lastName" :rules="rules.lastName"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <div class="v-label theme--light">Citizenship*</div>
        <v-radio-group row mandatory v-model="identificationType">
          <v-radio
            color="primary"
            v-for="(text, value) in choices.identificationTypes"
            :key="value"
            :label="text"
            :value="value"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <div>
      <v-row dense>
        <v-col cols="12">
          <v-text-field :label="identityNumberLabel" outlined v-model="identityNumber" :rules="rules.identityNumber" v-mask="identityNumberMask"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="mb-5">
          <div :class="{'v-label': true, 'theme--light': true, 'error--text': !!errors.identityDocuments}">{{ identityDocumentLabel }}</div>
          <file-button multiple accept=".png,.jpg,.jpeg,.gif,.pdf,.doc,.docx,.odt,.xls,.xlsx,.zip" v-model="identityDocuments">Upload</file-button>
          <div v-if="identificationType === 'South African ID' && !errors.identityDocuments" class="v-messages theme--light">Please note that for RSA Smart ID cards we will need copies of the front and back of the card.</div>
          <div v-if="errors.identityDocuments" class="v-messages theme--light error--text">{{ errors.identityDocuments }}</div>
          <!--<v-file-input outlined :label="identityDocumentLabel" required accept=".png,.jpg,.jpeg,.gif,.pdf,.doc,.docx,.odt,.xls,.xlsx,.zip" :rules="rules.identityDocument" prepend-icon="" prepend-inner-icon="mdi-paperclip" v-model="identityDocument" required></v-file-input>-->
        </v-col>
      </v-row>
    </div>
    <v-row dense>
      <v-col cols="12">
        <v-text-field label="South African Income Tax Number*" outlined v-model="incomeTaxNumber" :rules="rules.incomeTaxNumber" v-mask="'######################################'"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <!--<v-text-field label="Cellphone Number*" outlined v-model="cellphoneNumber" :rules="rules.cellphoneNumber"/>-->
        <phone-number-input outlined v-model="cellphoneNumber" label="Cellphone Number*" required :rules="rules.cellphoneNumber"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-text-field label="Email Address*" outlined v-model="emailAddress" :rules="rules.emailAddress"/>
      </v-col>
    </v-row>

    <v-btn color="primary" @click="nextClick" rounded>Next&nbsp;<v-icon right>mdi-arrow-right</v-icon></v-btn>
  </v-form>
</template>

<script>
import PhoneNumberInput from '../../PhoneNumberInput';
import FileButton from '../../FileButton';

export default {
  components: {
    PhoneNumberInput,
    FileButton
  },
  data() { 
    return {
      choices: {
        identificationTypes: {
          "South African ID": "South African Citizen",
          "Passport": "Foreign National"
        }
      },
      errors: {
        identityDocuments: ""
      },
      rules: {
        firstName: [ v => !!v || 'First Name is required' ],
        lastName: [ v => !!v || 'Last Name is required'],
        identityNumber: [
          v => !!v || `${this.identificationType} is required`,
          v => (this.identificationType === 'Passport' || (this.identificationType === 'South African ID' && v.length === 13)) || `Please enter a valid ${this.identificationType}`
        ],
        incomeTaxNumber: [ v => !!v || 'South African Income Tax Number is required' ],
        cellphoneNumber: [ v => !!v || 'Cellphone Number is required' ],
        emailAddress: [ 
          v => !!v || 'Email Address is required',
          v => /\S+@\S+\.\S+/.test(v) || 'Please enter a valid Email Address' 
        ]
      }
    }
  },
  computed: {
    firstName: {
      get() { return this.$store.state.firstName },
      set(value) { this.$store.commit('changeFirstName', value) }
    },
    middleNames: {
      get() { return this.$store.state.middleNames },
      set(value) { this.$store.commit('changeMiddleNames', value) }
    },
    lastName: {
      get() { return this.$store.state.lastName },
      set(value) { this.$store.commit('changeLastName', value) }
    },
    identificationType: {
      get() { return this.$store.state.identificationType; },
      set(value) { this.$store.commit('changeIdentificationType', value); }
    },
    identityNumber: {
      get() { return this.$store.state.identityNumber },
      set(value) { this.$store.commit('changeIdentityNumber', value) }
    },
    identityDocuments: {
      get() { return this.$store.state.identityDocuments; },
      set(value) { this.$store.commit('changeIdentityDocuments', value); }
    },
    incomeTaxNumber: {
      get() { return this.$store.state.incomeTaxNumber },
      set(value) { this.$store.commit('changeIncomeTaxNumber', value) }
    },
    cellphoneNumber: {
      get() { return this.$store.state.cellphoneNumber },
      set(value) { this.$store.commit('changeCellphoneNumber', value) }
    },
    emailAddress: {
      get() { return this.$store.state.emailAddress },
      set(value) { this.$store.commit('changeEmailAddress', value) }
    },
    identityNumberLabel() {
      return `${(this.identificationType === 'South African ID' ? "South African Identity Number" : "Passport Number")}*`;
    },
    identityDocumentLabel() { 
      return `${(this.identificationType === 'South African ID' ? "South African Identity Document" : "Passport Document")}*`; 
    },
    identityNumberMask() { 
      return this.identificationType === 'South African ID' ? '#############' : '################################################';
    }
  },
  methods: {
    validatePage() {
      this.errors.identityDocuments = "";

      let valid = true;

      if (this.identityDocuments.length === 0) {
        this.errors.identityDocuments = "Please upload your identity document" + (this.identificationType === "South African ID" ? " and make sure you upload both the front and back of your South African ID." : ".");
        valid = false;
      }

      if (!this.$refs.personalDetailsForm.validate()) {
        valid = false;
      }

      return valid;
    },
    nextClick() { 
      if (this.validatePage()) {
        this.$emit('next');
      }
    }
  },
  watch: {
    identityDocuments() {
      if (this.identityDocuments.length === 0) {
        this.errors.identityDocuments = "Please upload your identity document" + (this.identificationType === "South African ID" ? " and make sure you upload both the front and back of your South African ID." : ".");
      } else {
        this.errors.identityDocuments = "";
      }
    }
  }
}
</script>
import * as XLSX from "xlsx";
import currencyFormatter from "./currency-formatter";

import store from "../store/index";

/**
 * Totals all the numbers in the specified column
 * @param {Array} data Arrays of arrays for the table of data
 * @param {Number} columnIndex The column index of the table of data that should be added
 * @return {Number} The total sum of all the cells in the column
 */
function totalColumn(data, columnIndex) {
  let total = 0;

  for (const row of data) {
    total += parseFloat(row[columnIndex] || 0);
  }

  return total;
}

/**
 * Adds headers to the assets/liabilities table
 * @param {Array} data The array of arrays for the assets/liabilities table
 * @param {String} type The name of the table (it's inserted in the header). Can be Assets or Liabilities
 * @return {Array} The array of arrays with the headers
 */
function addHeadersToData(data, type) {
  const currentYear = new Date().getFullYear();

  return [
    [
      type,
      "Description",
      currentYear.toString(),
      (currentYear - 1).toString(),
      (currentYear - 2).toString(),
    ],
    ...data,
  ];
}

/**
 * Adds totals to the assets/liabilities table (array of arrays)
 * @param {Array} data The data to add the totals to
 * @return {Array} The array of arrays with the Totals
 */
function addTotalsToData(data) {
  return [
    ...data,
    ["", "Totals:", totalColumn(data, 2), totalColumn(data, 3), totalColumn(data, 4)],
  ];
}

/**
 * Goes through all the number fields in the arrays
 * and formats them with the correct currencies.
 * @param {Array} data The data to convert the numbers in
 * @return {Array} The array of arrays with the formatted numbers
 */
function formatNumbersInData(data) {
  return data.map((row) => {
    return [
      row[0],
      row[1],
      currencyFormatter.format(row[2], "R"),
      currencyFormatter.format(row[3], "R"),
      currencyFormatter.format(row[4], "R"),
    ];
  });
}

/**
 * Converts the binary data for the Excel Spreadsheet into a File object.
 * @param {any} binaryData The binary Excel Spreadsheet
 * @return {File} The converted File object
 */
function convertBinaryDataToFile(binaryData) {
  const buffer = new ArrayBuffer(binaryData.length);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < binaryData.length; i++) view[i] = binaryData.charCodeAt(i) & 0xff;

  const blob = new Blob([buffer], { type: "application/octet-stream" });
  return new File([blob], "Assets and Liabilities.xlsx");
}

/**
 * Combines the Assets and Liabilities table into a single array
 * of arrays that can be used to generate the Excel file.
 * @param {Array} assetsData The array of arrays containing the assets table
 * @param {Array} liabilitiesData The array of arrays containing the liabilities table
 * @return {Array} The consolidated array of arrays
 */
function consolidateAssetsAndLiabilitiesArray(assetsData, liabilitiesData) {
  return [
    ["Assets", "", "", "", ""],
    ...assetsData,
    ["", "", "", "", ""],
    ["Liabilities", "", "", "", ""],
    ...liabilitiesData,
  ];
}

/**
 * Generates the assets and liabilities spreadsheet
 * using data from the Vuex store.
 * @return {File} The Excel spreadsheet File
 */
function generateAssetsLiabilitiesSpreadsheet() {
  // Create a new workbook to save the data in
  const workbook = XLSX.utils.book_new();
  workbook.Props = {
    Title: "Assets and Liabilities",
    Author: "Tax Clearance Certificate Application Form",
    CreatedDate: new Date(),
  };

  // Create the Assets and Liabilities sheets and add the data
  workbook.SheetNames.push("Assets and Liabilities");
  const worksheet = XLSX.utils.aoa_to_sheet(
    consolidateAssetsAndLiabilitiesArray(
      addHeadersToData(
        formatNumbersInData(
          addTotalsToData(store.state.assetsAndLiabilities.onlineEntryData.assets)
        ),
        "Asset"
      ),
      addHeadersToData(
        formatNumbersInData(
          addTotalsToData(store.state.assetsAndLiabilities.onlineEntryData.liabilities)
        ),
        "Liability"
      )
    )
  );
  workbook.Sheets["Assets and Liabilities"] = worksheet;

  // Return the worksheet as a File
  const file = convertBinaryDataToFile(XLSX.write(workbook, { bookType: "xlsx", type: "binary" }));

  return file;
}

export default {
  generateAssetsLiabilitiesSpreadsheet,
};

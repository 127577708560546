<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th width="15%">{{ type }}</th>
        <th width="25%">Description</th>
        <th width="20%">{{ new Date().getFullYear() }}</th>
        <th width="20%">{{ new Date().getFullYear() - 1 }}</th>
        <th width="20%">{{ new Date().getFullYear() - 2 }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in data" :key="index">
        <td>{{ row[0] }}</td>
        <td>{{ row[1] }}</td>
        <td>{{ format(row[2]) }}</td>
        <td>{{ format(row[3]) }}</td>
        <td>{{ format(row[4]) }}</td>
      </tr>
      <tr>
          <td></td>
          <th class="text-right">Total:</th>
          <td>{{ yearOne }}</td>
          <td>{{ yearTwo }}</td>
          <td>{{ yearThree }}</td>
        </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import currencyFormatter from '../../../../utils/currency-formatter';

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    sumColumn(columnIndex) {
      let total = 0;

      for (const row of this.data) {
        total += parseFloat(row[columnIndex] || 0);
      }
      
      return total;
    },
    format(value) { 
      return currencyFormatter.format(value, 'R');
    }
  },
  computed: {
    yearOne() {
      return currencyFormatter.format(this.sumColumn(2), 'R');
    },
    yearTwo() {
      return currencyFormatter.format(this.sumColumn(3), 'R');
    },
    yearThree() {
      return currencyFormatter.format(this.sumColumn(4), 'R');
    }
  },
}
</script>
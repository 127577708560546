<template>
  <div>
    <p>
      SARS requires a statement for the last 3 years detailing your South African Assets &
      Liabilities. If you have a recent statement you can upload that below, or you can complete one
      online using our provided template. Alternatively, click skip and you can email your statement
      through to us at a later stage.
    </p>

    <v-row dense class="pa-2">
      <v-col cols="12">
        <assets-and-liabilities-accordian v-model="selectedMethod" />
      </v-col>
    </v-row>

    <v-row dense v-if="error">
      <v-col cols="12">
        <p class="v-messages error--text">{{ error }}</p>
      </v-col>
    </v-row>

    <br />
    <v-btn text @click="$emit('back')" rounded
      ><v-icon left>mdi-arrow-left</v-icon>&nbsp;Back</v-btn
    >
    &nbsp;
    <v-btn color="primary" @click="nextClick" rounded
      >Next&nbsp;<v-icon right>mdi-arrow-right</v-icon></v-btn
    >
  </div>
</template>

<script>
import AssetsAndLiabilitiesAccordian from "./assets-and-liabilities-page/AssetsAndLiabilitiesAccordian";

export default {
  components: {
    AssetsAndLiabilitiesAccordian,
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    selectedMethod: {
      get() {
        return this.$store.state.assetsAndLiabilities.selectedMethod;
      },
      set(value) {
        this.$store.commit("assetsAndLiabilities/changeSelectedMethod", value);
      },
    },
  },
  methods: {
    nextClick() {
      if (this.selectedMethod === null || this.selectedMethod === undefined) {
        this.error = "Please select an option before continuing";
      } else if (
        this.selectedMethod === "upload" &&
        this.$store.state.assetsAndLiabilities.spreadsheetFiles.length === 0
      ) {
        this.error = "Please upload your own file containing your assets and liabilities";
      } else {
        this.$emit("next");
      }
    },
  },
};
</script>

export default {
  namespaced: true,
  state: {
    selectedMethod: null,
    spreadsheetFiles: [],
    onlineEntryData: {
      assets: [
        ['Property', '', 0, 0, 0],
        ['Vehicles', '', 0, 0, 0],
        ['Retirement Annuities', '', 0, 0, 0],
        ['Cash available', '', 0, 0, 0],
        ['Investments', '', 0, 0, 0],
        ['Other', '', 0, 0, 0],
      ], 
      liabilities: [
        ['Property Bond/s', '', 0, 0, 0],
        ['Vehicle repayment/s', '', 0, 0, 0],
        ['Credit Card/s', '', 0, 0, 0],
        ['Loan/s', '', 0, 0, 0],
        ['Other', '', 0, 0, 0]
      ]
    }
  },
  mutations: {
    changeSelectedMethod(state, payload) {
      state.selectedMethod = payload;
    },
    changeAssetsOnlineEntryData(state, payload) {
      state.onlineEntryData.assets = payload;
    },
    changeLiabilitiesOnlineEntryData(state, payload) {
      state.onlineEntryData.liabilities = payload;
    },
    changeSpreadsheetFiles(state, payload) {
      state.spreadsheetFiles = payload;
    }
  }
}
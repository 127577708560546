<template>
  
  <v-expansion-panels accordian tile popout v-model="selectedMethod" mandatory>
    <v-expansion-panel v-for="(method, index) in methods" :key="index">
      <v-expansion-panel-header :hide-actions="true">
        <v-radio-group hide-details v-model="selectedMethod" class="pa-0 ma-0">
          <v-radio hide-details class="inline-block ma-0 pa-0 flex-grow-0" color="primary" readonly :value="index"/>
        </v-radio-group>
        <span class="mt-1 font-weight-bold">{{ method.displayName }}</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <component :is="method.component"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  
</template>

<script>
import OnlineEntrySection from './OnlineEntrySection';
import UploadSpreadsheetSection from './UploadSpreadsheetSection';
import SkipSection from './SkipSection';

export default {
  components: {
    OnlineEntrySection,
    UploadSpreadsheetSection,
    SkipSection
  },
  props: {
    value: {
      type: String
    }
  },
  watch: {
    selectedMethod(index) {
      this.$emit('input', this.methods[index].name)
    }
  },
  data() { 
    const methods = [
      {
        displayName: 'Complete online template',
        name: 'online',
        component: 'OnlineEntrySection'
      },
      {
        displayName: 'Upload your own',
        name: 'upload',
        component: 'UploadSpreadsheetSection'
      },
      {
        displayName: 'Skip and provide later',
        name: 'skip',
        component: 'SkipSection'
      }
    ];

    return {
      selectedMethod: methods.findIndex(x => x.name === this.value),
      methods
    }
  }
}
</script>
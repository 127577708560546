import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store/index';
import environmentHelpers from './utils/environment-helpers';

import './plugins/vue-the-mask';
import './directives/border-radius';

Vue.config.productionTip = false

if (environmentHelpers.isTest()) {
  document.title = "TEST - " + document.title;
}

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#f7f8fa"}},[_c('page-container',[_c('v-card',{class:{
      'pa-1': _vm.$vuetify.breakpoint.smAndDown,
      'pa-2': _vm.$vuetify.breakpoint.mdAndUp,
      'my-12': _vm.$vuetify.breakpoint.mdAndUp,
      'my-6': _vm.$vuetify.breakpoint.smAndDown,
      'mx-6': _vm.$vuetify.breakpoint.mdAndUp,
      'mx-3': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"flat":"","tile":""}},[_c('v-container',[_vm._t("default")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
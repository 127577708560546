export default {
  namespaced: true,
  state: {
    loan: {
      "Loan Agreement": []
    },
    inheritance: {
      "Letter from the executor of estate and a copy of the L&D Account": [],
      "Latest statement of relevant bank account reflecting funds": []
    },
    saleOfProperty: {
      "Copies of Deed of Sale (Offer to Purchase)": [],
      "Transfer duty declaration & receipt": [],
      "Letter from Conveyancer confirming the transfer of property and statement of account": [],
      "Latest statement of relevant bank account, or letter from Conveyancer stating that the money will be transferred from Trust Account": []
    },
    donation: {
      "Details of donor": [],
      "Proof of donations tax paid on donation": [],
      "Latest bank statement of relevant bank account reflecting funds": []
    },
    shares: {
      "Letter from institution stating the transfer of the funds will happen after approval of TCC. Must contain the number of shares and estimated current market value available": []
    },
    bankAccount: {
      "3 months statement of relevant bank account reflecting funds": [],
      "Proof of source of savings; being where and how you obtained the savings": []
    },
    other: {}
  },
  mutations: {
    changeSourceOfFundFiles(state, payload) {
      state[payload.name] = payload.files;
    }
  }
};

<template>
  <v-simple-table>
    <thead>
      <tr>
        <th class="text-left" width="60%">Description</th>
        <th class="text-left" width="40%">Files</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(files, description) in descriptionFiles" :key="description">
        <td>{{ description }}</td>
        <td>
          <file-button small multiple :value="descriptionFiles[description]" accept=".png,.jpg,.jpeg,.gif,.pdf,.doc,.docx,.odt,.xls,.xlsx,.zip" @input="(files) => filesInput(files, description)">Upload</file-button>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import FileButton from "../../../FileButton";

export default {
  components: {
    FileButton
  },
  props: {
    value: {
      type: Object,
      default: new Object()
    }
  },
  methods: {
    filesInput(files, description) { 
      this.descriptionFiles[description] = files;

      this.$emit('input', this.descriptionFiles)
    }
  },
  data() { 
    return {
      descriptionFiles: this.value
    }
  }
}
</script>
<template>
  <v-card tile flat>
    <page-container>
      <v-container class="pa-6 d-flex justify-space-between align-center">
        <v-img src="../assets/logo.svg" width="120" class="flex-grow-0"/>
        <!-- 
          <div>
          <span class="font-weight-regular title grey--text text--darken-1" :hidden="$vuetify.breakpoint.smAndDown">Tax Clearance Certificate Application</span>
          &nbsp;
          <v-icon color="primary" :class="{ 'move-up': $vuetify.breakpoint.mdAndUp }">mdi-lock</v-icon>
        </div>
        -->
      </v-container>
    </page-container>
  </v-card>
</template>

<script>
import PageContainer from './PageContainer'

export default {
  components: {
    PageContainer
  }
}
</script>

<style>
.move-up {
  margin-top: -30px;
}
</style>
import axios from "axios";
import store from "../store/index";
import assetsAndLiabilitiesSpreadsheetService from "./assets-liabilities-spreadsheet";
import environmentHelpers from "./environment-helpers";

const uploadFiles = async (file) => {
  let url = "";
  if (environmentHelpers.isTest()) {
    url = "https://www.api.portal.test.mercury.global/api/v1/files/";
  } else {
    url = "https://www.api.portal.mercury.global/api/v1/files/";
  }

  let returnArray = [];

  const formData = new FormData();
  // const config = {
  //   headers: {
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS",
  //   },
  // };
  formData.append("file", file);
  // try {
  console.log("url", url);
  const res = await axios.post(url, formData);
  console.log("file upload", res);
  returnArray.push(res.data.data.fileId);
  // } catch (error) {
  //   throw new Error("Network Error", error);
  // }
  formData.delete("file");

  return returnArray;
};

async function submit() {
  const formData = new FormData();

  // Personal Details
  formData.append("firstName", store.state.firstName);
  formData.append("middleNames", store.state.middleNames);
  formData.append("lastName", store.state.lastName);
  formData.append("identityType", store.state.identificationType);
  formData.append("identityNumber", store.state.identityNumber);
  formData.append("incomeTaxNumber", store.state.incomeTaxNumber);
  formData.append("cellphoneNumber", store.state.cellphoneNumber);
  formData.append("emailAddress", store.state.emailAddress);

  // console.log("sjdhaskjhdjs", store.state.identityDocuments[0]);

  for (let i = 0; i < store.state.identityDocuments.length; i++) {
    try {
      const upload = await uploadFiles(store.state.identityDocuments[0]);
      formData.append(
        `identityDocument[${i}]`,
        JSON.stringify({
          name: store.state.identityDocuments[i].name,
          fileId: upload[0],
        })
      );
    } catch (error) {
      console.error("error with uploading", error);
    }
  }

  // Foreign Investment Details
  formData.append("investmentAmount", store.state.investmentAmount);
  formData.append("investmentType", store.state.investmentType);
  formData.append("investmentCountry", store.state.investmentCountry);
  formData.append("beneficiaryOfTrust", store.state.beneficiaryOfTrust),
    formData.append("existingLoanToTrust", store.state.existingLoanToTrust),
    formData.append("shareholding20OrMore", store.state.shareholding20OrMore);

  // Assets and liabilities spreadsheet
  if (store.state.assetsAndLiabilities.selectedMethod === "upload") {
    const files = store.state.assetsAndLiabilities.spreadsheetFiles;

    for (let i = 0; i < files.length; i++) {
      try {
        const upload = await uploadFiles(files[i]);
        formData.append(
          `assetsLiabilitiesSpreadsheet[${i}]`,
          JSON.stringify({
            name: files[i].name,
            fileId: upload[0],
          })
        );
      } catch (error) {
        console.error("error uploading asstes and liabilites", error);
      }
    }
  } else if (store.state.assetsAndLiabilities.selectedMethod === "online") {
    const file = assetsAndLiabilitiesSpreadsheetService.generateAssetsLiabilitiesSpreadsheet();
    try {
      const upload = await uploadFiles(file);
      formData.append(
        "assetsLiabilitiesSpreadsheet[0]",
        await JSON.stringify({
          name: "assetsLiabilitiesSpreadsheet.xlsx",
          fileId: upload[0],
        })
      );
    } catch (error) {
      console.error("error uploading asstes and liabilites", error);
    }
  }

  // Source of funds
  formData.append("sourceOfFunds", JSON.stringify(store.state.sourceOfFunds));

  // Source of funds files
  for (const source of store.state.sourceOfFunds) {
    let fileCounter = 0;
    for (const description of Object.keys(store.state.sourceOfFundFiles[source])) {
      for (const file of store.state.sourceOfFundFiles[source][description]) {
        const formDataPrefix = "sourceOfFunds." + source + ".files[" + fileCounter + "]";
        try {
          const upload = await uploadFiles(file);
          formData.append(
            formDataPrefix + ".file",
            JSON.stringify({
              name: file.name,
              fileId: upload[0],
            })
          );
          formData.append(formDataPrefix + ".description", description);

          fileCounter++;
        } catch (error) {
          console.log("error with uploading source of funds");
        }
      }
    }
  }

  try {
    if (environmentHelpers.isTest()) {
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
    }

    const responseData = (await axios.post("/TaxClearance.ashx", formData)).data;

    if (responseData.statusCode !== 200) {
      return responseData.errors;
    }
  } catch (e) {
    return [
      "An error occurred while trying to submit your application. Please make sure you are connected to the internet and try again. If that does not work you can contact Mercury FX South Africa on +27 10 329 0470",
    ];
  }

  return null;
}

export default {
  submit,
};

<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th width="15%">{{ type }}</th>
          <th width="25%">Description</th>
          <th width="20%">{{ new Date().getFullYear() }}</th>
          <th width="20%">{{ new Date().getFullYear() - 1 }}</th>
          <th width="20%">{{ new Date().getFullYear() - 2 }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in tableData" :key="index">
          <td>{{ row[0] }}</td>
          <td><v-text-field hide-details dense outlined v-model="row[1]" /></td>
          <td><currency-input hide-details dense outlined prefix="R" v-model="row[2]"/></td>
          <td><currency-input hide-details dense outlined prefix="R" v-model="row[3]"/></td>
          <td><currency-input hide-details dense outlined prefix="R" v-model="row[4]"/></td>
        </tr>
        <tr>
          <td></td>
          <th class="text-right">Total:</th>
          <td>{{ yearOne }}</td>
          <td>{{ yearTwo }}</td>
          <td>{{ yearThree }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
// import currencyFormatter from '../../../../utils/currency-formatter';
import currencyFormatter from '../../../../utils/currency-formatter';
import CurrencyInput from '../../../CurrencyInput';

export default {
  components: {
    CurrencyInput,
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableData: this.value,
      money: {
        decimal: '.', 
        thousands: ' ',
        prefix: '',
        precision: 2,
        masked: false
      }
    }
  },
  methods: {
    sumColumn(columnIndex) {
      let total = 0;

      for (const row of this.tableData) {
        total += parseFloat(row[columnIndex] || 0);
      }
      
      return total;
    },
    handleInput() {
      this.$emit('input', this.tableData);
    }
  },
  computed: {
    yearOne() {
      return currencyFormatter.format(this.sumColumn(2), 'R');
    },
    yearTwo() {
      return currencyFormatter.format(this.sumColumn(3), 'R');
    },
    yearThree() {
      return currencyFormatter.format(this.sumColumn(4), 'R');
    }
  },
  watch: {
    tableData(data) { 
      this.$emit('input', data);
    }
  }
}
</script>